<template>
  <div class="container">

    <div class="buttons-group">
      <div
        v-for="(comp, index) in allCompanies"
        :key="comp.name"
        >
        <button
          @click="selectItem(index)"
          class="btn"
          :class="{
            'btn-default': index !== curentCompany,
            'btn-primary': index === curentCompany,
          }"
          type="button"
          >
          {{ index + 1 }}. {{ toStr(comp) }}
        </button>

        <button
          class="btn btn-small"
          type="button"
          @click="removeItem(index)"
          >
          &times;
        </button>
      </div>
    </div>

    <p>Lub dodaj nową:</p>

    <div class="form-group">
      <div class="form-group">
        <label for="companyName" class="form-label">Nazwa firmy</label>
        <input id="companyName" type="text" class="form-control" v-model="company.name">
      </div>

      <fieldset>
        <div class="form-group">
          <label for="street" class="form-label">Ulica</label>
          <input id="street" type="text" class="form-control" v-model="company.street">
        </div>
        <div class="row">
          <div class="col-sm-4">
            <div class="form-group">
              <label for="zipCode" class="form-label">Kod pocztowy</label>
              <input id="zipCode" type="text" class="form-control" v-model="company.zipCode">
            </div>
          </div>
          <div class="col-sm-8">
            <div class="form-group">
              <label for="city" class="form-label">Miasto</label>
              <input id="city" type="text" class="form-control" v-model="company.city">
            </div>
          </div>
        </div>
      </fieldset>

      <div class="form-group">
        <label for="nip" class="form-label">NIP</label>
        <input id="nip" type="text" class="form-control" v-model="company.nip">
      </div>
    </div>

    <div class="actions">
      <button @click="save" class="btn btn-primary">Zapisz zmiany</button>
      <button @click="$router.go(-1)" class="btn btn-default">Wróć</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Settings',
  data: () => ({
    curentCompany: '',
    allCompanies: [],
    company: {
      name: '',
      street: '',
      zipCode: '',
      city: '',
      nip: '',
    },
  }),
  created() {
    const current = localStorage.getItem('currentCompany');
    this.curentCompany = Number(current);

    const all = localStorage.getItem('allCompanies');

    if (all) {
      this.allCompanies = [
        ...this.allCompanies,
        ...JSON.parse(all),
      ];
    }
  },
  computed: {
    companyString: () => this.toStr(this.company),
  },
  methods: {
    toStr({
      name,
      street,
      zipCode,
      city,
      nip,
    }) {
      const address = `${street}, ${zipCode} ${city}`;
      return `${name}, ${address}, NIP: ${nip}`;
    },
    save() {
      const hasCompanyIndex = this.allCompanies.findIndex((el) => el.nip === this.company.nip);

      if (hasCompanyIndex === -1) {
        this.allCompanies.push(this.company);
        this.curentCompany = this.allCompanies.length - 1;
      } else {
        this.curentCompany = hasCompanyIndex;
      }

      localStorage.setItem('currentCompany', this.curentCompany);
      localStorage.setItem('allCompanies', JSON.stringify(this.allCompanies));

      this.$router.go(-1);
    },
    selectItem(index) {
      this.curentCompany = Number(index);
      localStorage.setItem('currentCompany', index);

      this.$router.go(-1);
    },
    removeItem(index) {
      this.$delete(this.allCompanies, index);

      localStorage.setItem('currentCompany', this.curentCompany);
      localStorage.setItem('allCompanies', JSON.stringify(this.allCompanies));
    },
  },
};
</script>

<style scoped lang="scss">
  .container {
    padding-top: 5rem;
    padding-bottom: 5rem;

    width: 40rem;
    max-width: 100%;
  }

  .buttons-group {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    gap: .25rem;

    div {
      display: flex;
      align-items: center;
      gap: .25rem;
    }

    .btn-default,
    .btn-primary {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
      flex: 1 1 auto;
    }

    .btn-small {
      flex: 0 0 auto;
    }
  }

  .actions > .btn-primary {
    margin-right: .5rem;
  }

  p {
    opacity: .5;
    font-size: 1rem;
    text-transform: uppercase;
  }
</style>
